import axios from 'axios';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdmin } from '../features/adminsSlice';
import Snackbar from './snackbar/snackbar';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function AdminTable({ adminAccounts }) {
	const dispatch = useDispatch();
	const snackbarRef = useRef(null);
	const user = useSelector((state) => state.user.user);
	const delAdmin = (e, id) => {
		e.preventDefault();
		console.log(id);
		if (id !== 1) {
			const response = axios
				.delete(`https://api.akallivilla.gr/api/delUser/${id}`, {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				})
				.then((res) => {
					if (res.status === 200 || res.status === 201) {
						dispatch(deleteAdmin(id));
					}
				})
				.catch((error) => console.log(error));
		} else {
			return;
		}
	};
	return (
		<div class="flex flex-col h-full" style={{ marginTop: 32 }}>
			<div class="overflow-x-auto shadow-md sm:rounded-lg">
				<div class="inline-block min-w-full align-middle bg-gray-800">
					<div class="p-4">
						<label for="table-search" class="sr-only">
							Search
						</label>
						<Snackbar
							ref={snackbarRef}
							message="You delete one admin!"
							type={SnackbarType.success}
						/>
						<div class="relative mt-1">
							<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
								<svg
									class="w-5 h-5  text-gray-400"
									fill="currentColor"
									viewBox="0 0 20 20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
										clip-rule="evenodd"
									></path>
								</svg>
							</div>
						</div>
					</div>
					<div class="overflow-hidden">
						<table class="min-w-full divide-y  table-fixed divide-gray-700">
							<thead class="bg-gray-700">
								<tr>
									<th></th>
									<th
										scope="col"
										class="py-3 px-6 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
									>
										Name
									</th>
									<th
										scope="col"
										class="py-3 px-6 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
									>
										Email
									</th>
									<th scope="col" class="p-4 text-red-700">
										Delete
									</th>
								</tr>
							</thead>
							<tbody class=" divide-y  bg-gray-800 divide-gray-700">
								{adminAccounts.map((admin) => {
									return (
										<>
											<tr class="hover:bg-gray-700">
												<td class="p-4 w-4">
													<div class="flex items-center">
														<input
															id="checkbox-search-2"
															type="checkbox"
															class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
														/>
														<label
															for="checkbox-search-2"
															class="sr-only"
														>
															checkbox
														</label>
													</div>
												</td>
												<td class="py-4 px-6 text-sm font-medium  whitespace-nowrap text-white">
													{admin.name}
												</td>
												<td class="py-4 px-6 text-sm font-medium  whitespace-nowrap text-white">
													{admin.email}
												</td>

												<td class="py-4 px-6 text-sm font-medium text-center whitespace-nowrap">
													<button
														className="border-2 border-red-400 w-6 bg-red-400 rounded text-white font-bold"
														onClick={(e) =>
															delAdmin(
																e,
																admin.id
															)
														}
													>
														X
													</button>
												</td>
											</tr>
										</>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
}
