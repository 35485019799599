import React, { useState, useRef } from 'react';
import './css/createRoomModal.css';
import axios from 'axios';
import Snackbar from './snackbar/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { createRoom } from '../features/roomSlice';
import { Oval } from 'react-loader-spinner';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function CreateRoomModal() {
	const snackbarRef = useRef(null);
	const [display, setDisplay] = useState(false);
	const [hover, setHover] = useState(false);
	const [roomName, setRoomName] = useState('');
	const [desc, setDescription] = useState('');
	const [price, setPrice] = useState(0);

	const [minVisitors, setMinVisitors] = useState(0);
	const [maxVisitors, setMaxVisitors] = useState(0);
	const [mainImage, setMainImage] = useState(null);
	const [secondaryImage, setSecondaryImages] = useState([]);
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const handleSubmit = (e) => {
		e.preventDefault();
		var formData = new FormData();
		formData.append('name', roomName);
		formData.append('description', desc);
		formData.append('price', price);

		formData.append('max_visitors', maxVisitors);
		formData.append('min_visitors', minVisitors);
		formData.append('main_image', mainImage);

		for (let i = 0; i < secondaryImage.length; i++) {
			formData.append(`secondary_images[${i}]`, secondaryImage[i]);
		}
		setLoading(true);
		const response = axios
			.post('https://api.akallivilla.gr/api/rooms', formData, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${user.token}`,
				},
			})
			.then((response) => {
				dispatch(createRoom(response.data.room));
				setLoading(false);
				setDisplay(false);
				snackbarRef.current.show();
			})

			.catch((error) => {
				console.log(error);
				setLoading(false);
				setDisplay(false);
			});
	};
	return (
		<>
			<button
				onClick={() => setDisplay(true)}
				className="mb-8 mx-auto border-2 border-gray-800 text-xl p-2 rounded-xl hover:border-white hover:text-gray-800 hover:bg-white transition duration-500 ease-in-out"
			>
				Create Room
			</button>
			<Snackbar
				ref={snackbarRef}
				message="Room Created Successfully"
				type={SnackbarType.success}
			/>
			<div
				id="myModal"
				class="modal"
				style={{ display: display ? 'block' : 'none' }}
			>
				<span class="close" onClick={() => setDisplay(false)}>
					&times;
				</span>
				{loading ? (
					<div
						style={{
							width: 80,
							height: 80,
							display: 'block',
							marginLeft: 'auto',
							marginRight: 'auto',
							marginTop: 150,
						}}
					>
						<Oval color="#00BFFF" height={80} width={80} />
					</div>
				) : (
					<form
						className="bg-gray-800 mx-32 rounded-2xl py-4 shadow-2xl"
						encType="multipart/form-data"
					>
						<div
							className="it"
							style={{
								placeItems: 'center',
								marginTop: 64,
							}}
						>
							<input
								onChange={(e) => setRoomName(e.target.value)}
								type="text"
								placeholder="Room Name"
								className="mr-auto ml-auto"
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.4)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 600,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<div>
							<input
								type="text"
								placeholder="Description"
								onChange={(e) => setDescription(e.target.value)}
								className="mr-auto ml-auto"
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.4)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 600,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<div>
							<input
								type="number"
								placeholder="Price"
								onChange={(e) => setPrice(e.target.value)}
								className="mr-auto ml-auto"
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.4)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 600,
									fontStyle: 'italic',
								}}
							/>
						</div>

						<div>
							<input
								type="number"
								placeholder="Minimun Visitors"
								onChange={(e) => setMinVisitors(e.target.value)}
								className="mr-auto ml-auto"
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.4)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 600,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<div>
							<input
								type="number"
								placeholder="Maximun Visitors"
								onChange={(e) => setMaxVisitors(e.target.value)}
								className="mr-auto ml-auto"
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.4)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 600,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<div>
							<label className="text-white text-center mx-auto block">
								Main Image
							</label>
							<input
								type="file"
								placeholder="Main Image"
								className="mr-auto ml-auto"
								onChange={(e) =>
									setMainImage(e.target.files[0])
								}
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.4)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 600,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<div>
							<label className="text-white text-center mx-auto block">
								Secondary Images
							</label>
							<input
								type="file"
								onChange={(e) =>
									setSecondaryImages(e.target.files)
								}
								multiple
								name="file[]"
								placeholder="Secondary Images"
								className="mr-auto ml-auto"
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.4)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 600,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<button
							onMouseEnter={() => setHover(!hover)}
							onMouseLeave={() => setHover(!hover)}
							onClick={handleSubmit}
							type="submit"
							style={{
								marginLeft: 'auto',
								marginRight: 'auto',
								display: 'block',
								backgroundColor: 'white',
								color: hover ? 'black' : 'white',
								padding: 16,
								borderRadius: 18,
								fontSize: 16,
								fontWeight: 600,
								background:
									'linear-gradient(to left, #282928 50%, lightblue 50%) right',
								backgroundSize: '200%',
								transition: '.5s ease-out',
								backgroundPosition: hover ? 'left' : 'right',
							}}
						>
							Create Room
						</button>
					</form>
				)}
			</div>
		</>
	);
}
