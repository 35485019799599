import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeCheckIn, deleteBook } from '../features/booksSlice';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SingleRowBook from './singleRowBook';
export default function BooksContent() {
	const books = useSelector((state) => state.books.books);
	const [test, setTest] = useState(books);
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const [excludedDates, setExcludedDates] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchData = async () => {
			const response = await axios
				.get(`https://api.akallivilla.gr/api/bookings/closedDates`)
				.then((res) => {
					if (res.data) {
						return res.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
			let dates = [];
			for (let i = 0; i < response.length; i++) {
				const start = new Date(response[i].start);
				const end = new Date(response[i].end);
				let moving = start;
				while (moving <= new Date(end)) {
					dates.push(new Date(moving));
					moving.setUTCDate(moving.getUTCDate() + 1);
				}
			}
			setExcludedDates([...dates]);
		};
		fetchData();
	}, []);

	return (
		<div className="col-span-8 px-32 h-full min-h-screen">
			<div class="flex flex-col" style={{ marginTop: 32 }}>
				<div class="overflow-x-auto shadow-md sm:rounded-lg">
					<div class="inline-block min-w-full align-middle bg-gray-800">
						<div class="overflow-hidden">
							<table class="min-w-full divide-y  table-fixed divide-gray-700">
								<thead class=" bg-gray-700">
									<tr>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
										>
											Book ID
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
										>
											Room ID
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
										>
											Check In/Check Out
										</th>

										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
										>
											Customer ID
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
										>
											Guests
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
										>
											Price
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left  uppercase text-gray-400"
										>
											Status
										</th>
										<th
											scope="col"
											class="p-1 text-red-700"
										>
											Delete
										</th>
										<th
											scope="col"
											class="p-1 text-red-700"
										>
											Update
										</th>
									</tr>
								</thead>
								<tbody class=" divide-y bg-gray-800 divide-gray-700">
									{books.map((book) => {
										return (
											<>
												<SingleRowBook book={book} />
											</>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
