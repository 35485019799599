import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCustomer } from '../features/customersSlice';
import Snackbar from './snackbar/snackbar';

const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function CustomerModal({ id }) {
	const snackbarRef = useRef(null);
	const customers = useSelector((state) => state.customers.customers);
	const user = useSelector((state) => state.user.user);
	const [display, setDisplay] = useState(false);
	const [hover, setHover] = useState(false);
	const [customer, setCustomer] = useState(null);
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const dispatch = useDispatch();
	const updateCustomer2 = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('customer_first_name', fname);
		formData.append('customer_last_name', lname);
		formData.append('customer_email', email);
		formData.append('customer_phone', phone);
		const response = await axios
			.post(`https://api.akallivilla.gr/api/customers/${id}`, formData, {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			})
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					dispatch(
						updateCustomer({ id, fname, lname, email, phone })
					);
					setDisplay(false);
					snackbarRef.current.show();
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	useEffect(() => {
		setCustomer(customers.filter((cust) => cust.id === id));
	}, []);
	useEffect(() => {
		if (customer && customer[0]) {
			setFname(customer[0].customer_first_name);
			setLname(customer[0].customer_last_name);
			setEmail(customer[0].customer_email);
			setPhone(customer[0].customer_phone);
		}
	}, [customer]);
	return (
		<>
			<button
				onClick={() => setDisplay(true)}
				className=" mx-auto text-sm  px-6 rounded-xl hover:border-white hover:text-gray-800 hover:bg-white transition duration-500 ease-in-out"
			>
				{id}
			</button>
			<Snackbar
				ref={snackbarRef}
				message="Room Created Successfully"
				type={SnackbarType.success}
			/>

			{customer ? (
				<div
					id="myModal"
					class="modal"
					style={{ display: display ? 'block' : 'none' }}
				>
					<span class="close" onClick={() => setDisplay(false)}>
						&times;
					</span>
					<form
						className="bg-gray-800 mx-32 rounded-2xl py-4 shadow-2xl"
						encType="multipart/form-data"
					>
						<h3 className="text-center text-2xl mt-4">
							Customer Details
						</h3>
						<div
							className="it"
							style={{
								placeItems: 'center',
								marginTop: 64,
							}}
						>
							<input
								type="text"
								placeholder={fname}
								className="mr-auto ml-auto"
								onChange={(e) => setFname(e.target.value)}
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.1)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 800,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<div>
							<input
								type="text"
								placeholder={lname}
								onChange={(e) => setLname(e.target.value)}
								className="mr-auto ml-auto"
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.1)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 800,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<div>
							<input
								placeholder={email}
								className="mr-auto ml-auto"
								onChange={(e) => setEmail(e.target.value)}
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.1)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 800,
									fontStyle: 'italic',
								}}
							/>
						</div>
						<div>
							<input
								placeholder={phone}
								className="mr-auto ml-auto"
								onChange={(e) => setPhone(e.target.value)}
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,
									display: 'block',
									background: 'rgba(255,255,255,.1)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 800,
									fontStyle: 'italic',
								}}
							/>
						</div>

						<button
							onMouseEnter={() => setHover(!hover)}
							onMouseLeave={() => setHover(!hover)}
							type="submit"
							onClick={(e) => updateCustomer2(e)}
							style={{
								marginLeft: 'auto',
								marginRight: 'auto',
								display: 'block',
								backgroundColor: 'white',
								color: hover ? 'black' : 'white',
								padding: 16,
								borderRadius: 18,
								fontSize: 16,
								fontWeight: 600,
								background:
									'linear-gradient(to left, #282928 50%, lightblue 50%) right',
								backgroundSize: '200%',
								transition: '.5s ease-out',
								backgroundPosition: hover ? 'left' : 'right',
							}}
						>
							Update Customer
						</button>
					</form>
				</div>
			) : (
				''
			)}
		</>
	);
}
