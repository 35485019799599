import axios from 'axios';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { login } from '../features/userSlice';
import Home from '../pages/home';
import Snackbar from './snackbar/snackbar';

const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function LoginForm(props) {
	const snackbarRef = useRef(null);
	const navigate = useNavigate();
	const [hover, setHover] = useState(false);
	const [logo, setLogo] = useState(false);
	const [Email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('email', Email);
		formData.append('password', password);

		const response = axios
			.post('https://api.akallivilla.gr/api/login', formData)
			.then((response) => {
				dispatch(
					login({
						name: response.data.user.name,
						email: response.data.user.email,
						token: response.data.token,
					})
				);

				navigate('/');
			})
			.catch((error) => snackbarRef.current.show());
	};
	return (
		<div
			className="items-center bg-gray-700 shadow-2xl"
			style={{
				marginLeft: 'auto',
				marginRight: 'auto',
				width: 400,

				borderRadius: 20,
			}}
		>
			<Snackbar
				ref={snackbarRef}
				message="Wrong Password or Email"
				type={SnackbarType.fail}
			/>
			<div className="pt-2">
				<img
					onMouseEnter={() => setLogo(!logo)}
					onMouseLeave={() => setLogo(!logo)}
					src={props.logo}
					alt="Logo"
					width={120}
					style={{
						borderRadius: '100%',
						marginLeft: 'auto',
						marginRight: 'auto',
						marginTop: 32,

						transform: logo ? 'rotate(360deg)' : '',
						transition: 'transform 1s ease',
						cursor: 'pointer',
					}}
				/>
			</div>
			<form
				style={{
					marginTop: 64,
					alignItems: 'center',
					height: 300,
					paddingTop: 20,
				}}
			>
				<input
					placeholder="Email"
					onChange={(e) => setEmail(e.target.value)}
					style={{
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto',
						height: 50,
						borderRadius: 20,
						width: '80%',
						paddingLeft: 24,
					}}
				/>
				<input
					onChange={(e) => setPassword(e.target.value)}
					placeholder="Password"
					type="password"
					style={{
						marginTop: 32,
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto',
						height: 50,
						borderRadius: 20,
						width: '80%',
						paddingLeft: 24,
					}}
				/>
				<button
					onMouseEnter={() => setHover(!hover)}
					onMouseLeave={() => setHover(!hover)}
					onClick={handleSubmit}
					style={{
						marginTop: 32,
						marginLeft: 'auto',
						marginRight: 'auto',
						display: 'block',
						borderRadius: 20,
						backgroundColor: hover ? 'white' : 'black',
						color: hover ? 'black' : 'white',
						width: 120,
						height: 40,
						fontWeight: 600,
						transition:
							'background-color 0.4s ease,color 0.4s ease',
					}}
				>
					Log In
				</button>
			</form>
		</div>
	);
}
