import React, { useEffect } from 'react';
import LoginForm from '../components/loginForm';
import { selectUser } from '../features/userSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Home from './home';
import { Navigate } from 'react-router';
export default function Login() {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);

	useEffect(() => {
		if (user.token) {
			navigate('/');
		}
	}, []);
	return (
		<>
			<div className="bg-gray-600 md:h-screen h-full md:pt-32 pt-12">
				<LoginForm logo="assets/logo.jpg" />
			</div>
		</>
	);
}
