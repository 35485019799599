import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/userSlice';
import roomsReducer from '../features/roomSlice';
import booksReducer from '../features/booksSlice';
import customersReducer from '../features/customersSlice';
import adminsReducer from '../features/adminsSlice';
import datesReducer from '../features/dateSlice';
import galleryReducer from '../features/gallerySlice';
import storage from 'redux-persist/lib/storage';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
const persistConfig = {
	key: 'root2',
	storage,
};
const combReduces = combineReducers({
	user: userReducer,
	rooms: roomsReducer,
	books: booksReducer,
	customers: customersReducer,
	admins: adminsReducer,
	dates: datesReducer,
	gallery: galleryReducer,
});
const persistedReducer = persistReducer(persistConfig, combReduces);
export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [
					FLUSH,
					REHYDRATE,
					PAUSE,
					PERSIST,
					PURGE,
					REGISTER,
				],
			},
		}),
});
export const persistor = persistStore(store);
