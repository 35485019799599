import { createSlice } from '@reduxjs/toolkit';

export const roomsSlice = createSlice({
	name: 'rooms',
	initialState: {
		rooms: [],
	},
	reducers: {
		addRooms: (state, action) => {
			state.rooms = action.payload;
		},
		createRoom: (state, action) => {
			state.rooms = [...state.rooms, action.payload];
		},
		deleteRoom: (state, action) => {
			let arr = state.rooms.filter((ad) => ad.id !== action.payload);
			state.rooms = arr;
		},
		updateRoom: (state, action) => {
			state.rooms = state.rooms.map((card) => {
				if (card.id === action.payload.id) {
					return {
						...card,
						description: action.payload.description,
						min_visitors: action.payload.minVisitors,
						max_visitors: action.payload.maxVisitors,
						name: action.payload.name,
						price: action.payload.price,
					};
				}
				return card;
			});
		},
		deleteImage: (state, action) => {
			state.rooms = state.rooms.map((room) => {
				if (room.id === action.payload.id) {
					const images = room.secondary_images.filter(
						(imag) => imag !== action.payload.imageUrl
					);
					return { ...room, secondary_images: [...images] };
				}
				return room;
			});
		},
		reOrderRoom: (state, action) => {
			state.rooms = state.rooms.map((room) => {
				if (room.id === action.payload.id) {
					return {
						...room,
						secondary_images: action.payload.images,
					};
				}
				return room;
			});
		},
		addImage: (state, action) => {
			state.rooms = state.rooms.map((room) => {
				if (room.id === action.payload.id) {
					return {
						...room,
						description: action.payload.description,
						min_visitors: action.payload.min_visitors,
						max_visitors: action.payload.max_visitors,
						name: action.payload.name,
						price: action.payload.price,
						main_image: action.payload.main_image,
						secondary_images: action.payload.secondary_images,
					};
				}
				return room;
			});
		},
		addImageSecondary: (state, action) => {
			state.rooms = state.rooms.map((room) => {
				if (room.id === action.payload.id) {
					return {
						...room,
						description: action.payload.description,
						min_visitors: action.payload.min_visitors,
						max_visitors: action.payload.max_visitors,
						name: action.payload.name,
						price: action.payload.price,
						main_image: action.payload.main_image,
						secondary_images: [...action.payload.secondary_images],
					};
				}
				return room;
			});
		},
		logoutRoom: (state) => {
			state.rooms = [];
		},
	},
});
export const {
	addRooms,
	logoutRoom,
	createRoom,
	deleteRoom,
	updateRoom,
	addImage,
	addImageSecondary,
	deleteImage,
	reOrderRoom,
} = roomsSlice.actions;
export const selectUser = (state) => state.user;
export default roomsSlice.reducer;
