import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Profile from './pages/profile';
import { useEffect, useState } from 'react';
import Home from './pages/home';
import Login from './pages/login';
import Rooms from './pages/rooms';
import Books from './pages/books';
import Room from './pages/room';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { addRooms } from './features/roomSlice';
import { addBooks } from './features/booksSlice';
import { addCustomers } from './features/customersSlice';
import { addAdmins } from './features/adminsSlice';
import { allDates } from './features/dateSlice';
import Customer from './pages/customer';
import Book from './pages/book';
import Mail from './pages/mail';
import Gallery from './pages/gallery';
import { addGallery } from './features/gallerySlice';
function App() {
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const [isAuthenticated, userHasAuthenticated] = useState(false);

	const fetchAllData = async () => {
		if (user) {
			const rooms = await axios
				.get('https://api.akallivilla.gr/api/rooms', {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				})
				.then((response) => {
					console.log(response.data);
					return response.data;
				})
				.catch((error) => console.log(error));
			const books = await axios
				.get('https://api.akallivilla.gr/api/bookings', {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				})
				.then((response) => {
					return response.data;
				});
			const customers = await axios
				.get('https://api.akallivilla.gr/api/customers', {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				})
				.then((response) => {
					return response.data;
				});

			const admins = await axios
				.get('https://api.akallivilla.gr/api/users', {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				})
				.then((response) => {
					return response.data;
				});
			dispatch(addAdmins(admins));

			const dates = await axios
				.get('https://api.akallivilla.gr/api/bookings/closedDates', {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				})
				.then((response) => {
					return response.data;
				});
			const gallery = await axios
				.get('https://api.akallivilla.gr/api/galleries', {
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				})
				.then((response) => {
					return response.data;
				});
			dispatch(addRooms(rooms));
			dispatch(addBooks(books));
			dispatch(addCustomers(customers));
			dispatch(allDates(dates));
			dispatch(addGallery(gallery));
		}
	};
	useEffect(() => {
		if (user.token) {
			console.log('first');
			console.log(user.token);
			fetchAllData();
		}
	}, [user]);

	return (
		<Router>
			<div className="App">
				<Routes>
					<Route exact path="/" element={<Home user={user} />} />
					<Route
						exact
						path="/login"
						element={<Login user={user} />}
					/>
					<Route exact path="/profile/:id" element={<Profile />} />
					<Route exact path="/room/:id" element={<Room />} />
					<Route exact path="/customer/:id" element={<Customer />} />
					<Route exact path="/rooms" element={<Rooms />} />
					<Route exact path="/books" element={<Books />} />
					<Route exact path="/book/:id" element={<Book />} />
					<Route exact path="/mails" element={<Mail />} />
					<Route exact path="/gallery" element={<Gallery />} />
				</Routes>
			</div>
		</Router>
	);
}

export default App;
