import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import GallerySection from '../components/gallerySection';
import Profile from '../components/profile';
import Sidebar from '../components/sidebar';
export default function Gallery() {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);

	useEffect(() => {
		if (!user.token) {
			navigate('/login');
		}
		if (Object.entries(user).length !== 0) {
		}
	}, []);
	return (
		<div className="grid grid-cols-9 bg-gray-600 h-full min-h-screen">
			<Sidebar brandName="Akalli" logo="../assets/logo.jpg" />
			<GallerySection />
		</div>
	);
}
