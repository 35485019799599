import React, { useState } from 'react';
import {
	AiOutlineMenu,
	AiOutlineArrowLeft,
	AiOutlineArrowRight,
} from 'react-icons/ai';
import { IoMdAnalytics } from 'react-icons/io';
import { FaUserAlt } from 'react-icons/fa';
import { BiLogOut } from 'react-icons/bi';
import { BsFillHouseDoorFill, BsBookFill } from 'react-icons/bs';
import { GrGallery } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { logout } from '../features/userSlice';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';
import { logoutBook } from '../features/booksSlice';
import { logoutCustomer } from '../features/customersSlice';
import { logoutDates } from '../features/dateSlice';
import { logoutGallery } from '../features/gallerySlice';
import { logoutRoom } from '../features/roomSlice';
export default function Sidebar(props) {
	const [close, setClose] = useState(true);
	const id = 2;
	const dispatch = useDispatch();
	const userLogout = () => {
		dispatch(logout());
		dispatch(logoutRoom());
		dispatch(logoutCustomer());
		dispatch(logoutDates());
		dispatch(logoutGallery());
		dispatch(logoutBook());
		return <Navigate to="/login" />;
	};
	return (
		<div
			className={` ${close ? 'col-span-1' : 'col-span-1'} ${
				close ? 'w-full' : 'w-1/4'
			} bg-gray-800 fixed`}
			style={{
				position: 'sticky',

				transition: 'width .5s ease',
			}}
		>
			<div className="sticky top-0  font-light h-full w-1/3 lg:w-auto overflow-y-scroll  lg:overflow-y-hidden">
				{close ? (
					<button
						onClick={() => setClose(!close)}
						style={{ dispaly: 'inline-block', width: '100%' }}
					>
						{' '}
						<AiOutlineArrowLeft
							style={{
								fontSize: close ? 32 : 22,
								transition: 'fontSize .5s ease',
								color: 'white',
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
								textAlign: 'center',
							}}
						/>{' '}
					</button>
				) : (
					<button
						onClick={() => setClose(!close)}
						style={{ dispaly: 'inline-block', width: '100%' }}
					>
						{' '}
						<AiOutlineArrowRight
							style={{
								fontSize: close ? 32 : 22,
								transition: 'fontSize .5s ease',
								color: 'white',
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
								textAlign: 'center',
							}}
						/>{' '}
					</button>
				)}

				{close ? (
					<Link to="/">
						<h3
							style={{
								fontSize: 32,
								color: 'white',
								fontWeight: 600,
								textAlign: 'center',
								marginTop: 32,
								borderBottom: '1px solid white',
							}}
						>
							{props.brandName}
						</h3>
					</Link>
				) : (
					<Link to="/">
						<img
							src={props.logo}
							width={60}
							alt="logo"
							style={{ marginTop: 32 }}
						/>
					</Link>
				)}
				{close ? (
					<Link to="/rooms">
						<h3
							style={{
								fontSize: 22,
								color: 'white',
								fontWeight: 600,
								textAlign: 'center',
								marginTop: 32,
							}}
						>
							Rooms
						</h3>
					</Link>
				) : (
					<Link to="/rooms">
						<BsFillHouseDoorFill
							style={{
								fontSize: 32,
								color: 'white',
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
							}}
						/>
					</Link>
				)}
				{close ? (
					<Link to="/books">
						<h3
							style={{
								fontSize: 22,
								color: 'white',
								fontWeight: 600,
								textAlign: 'center',
								marginTop: 32,
							}}
						>
							Books
						</h3>
					</Link>
				) : (
					<Link to="/books">
						<BsBookFill
							style={{
								fontSize: 32,
								color: 'white',
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
							}}
						/>
					</Link>
				)}
				{close ? (
					<Link to={`/profile/${id}`}>
						<h3
							style={{
								fontSize: 22,
								color: 'white',
								fontWeight: 600,
								textAlign: 'center',
								marginTop: 32,
							}}
						>
							Users
						</h3>
					</Link>
				) : (
					<Link to={`/profile/${id}`}>
						<FaUserAlt
							style={{
								fontSize: 32,
								color: 'white',
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
							}}
						/>
					</Link>
				)}
				{close ? (
					<Link to="/gallery">
						<h3
							style={{
								fontSize: 22,
								color: 'white',
								fontWeight: 600,
								textAlign: 'center',
								marginTop: 32,

								width: '100%',
							}}
						>
							Gallery
						</h3>
					</Link>
				) : (
					<Link to="/gallery">
						<GrGallery
							style={{
								fontSize: 32,
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
								color: 'white',
								backgroundColor: 'white',
							}}
						/>
					</Link>
				)}
				{/* {close ? (
					<Link to="/mails">
						<h3
							style={{
								fontSize: 22,
								color: 'white',
								fontWeight: 600,
								textAlign: 'center',
								marginTop: 32,

								width: '100%',
							}}
						>
							Mails
						</h3>
					</Link>
				) : (
					<Link to="/mails">
						<IoMdAnalytics
							style={{
								fontSize: 32,
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
								color: 'white',
							}}
						/>
					</Link>
				)} */}
				{close ? (
					<Link to="/login" onClick={() => userLogout()}>
						<h3
							style={{
								fontSize: 22,
								color: 'white',
								fontWeight: 600,
								textAlign: 'center',
								marginTop: 32,
								position: 'absolute',
								bottom: 0,
								backgroundColor: '#d4172a',
								width: '100%',
							}}
						>
							Log out
						</h3>
					</Link>
				) : (
					<Link to="login">
						<BiLogOut
							style={{
								fontSize: 32,
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
								position: 'absolute',
								bottom: 0,
								color: 'red',
							}}
						/>
					</Link>
				)}
			</div>
		</div>
	);
}
