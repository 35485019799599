import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteRoom, updateRoom } from '../features/roomSlice';
import RoomModalImage from './roomModalImage';
import Snackbar from './snackbar/snackbar';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function SingleRoomRow({ room }) {
	console.log(room);
	const snackbarRef = useRef(null);
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const [minVisitors, setMinVisitors] = useState(0);
	const [maxVisitors, setMaxVisitors] = useState(0);
	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [desc, setDesc] = useState('');
	const deleteRoomById = (e, id) => {
		e.preventDefault();
		const response = axios
			.delete(`https://api.akallivilla.gr/api/rooms/${id}`, {
				headers: { Authorization: `Bearer ${user.token}` },
			})
			.then((response) => dispatch(deleteRoom(id)))
			.catch((error) => console.log(error));
	};
	const updateRoomById = (e, id) => {
		const formData = new FormData();
		formData.append('name', name);
		formData.append('description', desc);
		formData.append('price', parseFloat(price));
		formData.append('facilities', room.facilities);
		formData.append('max_visitors', maxVisitors);
		formData.append('min_visitors', minVisitors);

		const response = axios
			.post(`https://api.akallivilla.gr/api/rooms/${id}`, formData, {
				headers: { Authorization: `Bearer ${user.token}` },
			})
			.then((response) => {
				snackbarRef.current.show();
				dispatch(
					updateRoom({
						id: id,
						minVisitors: minVisitors,
						maxVisitors: maxVisitors,
						name: name,
						price: parseFloat(price),
						description: desc,
					})
				);
			})
			.catch((error) => console.log(error));
	};
	useEffect(() => {
		setMinVisitors(room.min_visitors);
		setMaxVisitors(room.max_visitors);
		setName(room.name);
		setPrice(room.price);
		setDesc(room.description);
	}, []);
	return (
		<tr class="hover:bg-gray-700">
			<td class="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">
				<Link to={`/room/${room.id}`}>{room.id}</Link>
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<input
					type="text"
					defaultValue={room.name}
					className="bg-gray-800"
					onChange={(e) => setName(e.target.value)}
				/>
			</td>

			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<input
					type="text"
					defaultValue={room.description}
					className="bg-gray-800"
					onChange={(e) => setDesc(e.target.value)}
				/>
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<input
					type="number"
					defaultValue={room.min_visitors}
					className="bg-gray-800 w-16"
					onChange={(e) => setMinVisitors(e.target.value)}
				/>
			</td>

			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<input
					type="number"
					defaultValue={room.max_visitors}
					className="bg-gray-800 w-16"
					onChange={(e) => setMaxVisitors(e.target.value)}
				/>
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<RoomModalImage room={room} imageUrl={room.main_image} />
			</td>
			<td class="py-4 px-6 text-sm font-medium  whitespace-nowrap text-white">
				{room.secondary_images
					? room.secondary_images.map((image, index) => {
							if (index < 2) {
								return (
									<RoomModalImage
										room={room}
										imageUrl={image}
									/>
								);
							}
							return;
					  })
					: ''}
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<input
					type="number"
					defaultValue={room.price}
					className="bg-gray-800 w-16"
					onChange={(e) => setPrice(e.target.value)}
				/>
			</td>
			<td class="py-4 px-1 text-sm font-medium text-center whitespace-nowrap">
				<button
					className="border-2 border-red-400 w-6 bg-red-400 rounded text-white font-bold"
					onClick={(e) => deleteRoomById(e, room.id)}
				>
					X
				</button>
			</td>
			<td class="py-4 px-1 text-sm font-medium text-center whitespace-nowrap">
				<button
					className="border-2 border-gray-700 w-full bg-gray-700 rounded text-white font-bold"
					onClick={(e) => updateRoomById(e, room.id)}
				>
					Update
				</button>
			</td>
			<Snackbar
				ref={snackbarRef}
				message="Updated Successfully!"
				type={SnackbarType.success}
			/>
		</tr>
	);
}
