import React, { useState, useEffect, useRef } from 'react';
import { Calendar } from 'react-calendar';
import './css/calendar.css';
import { isWithinInterval } from 'date-fns';
import axios from 'axios';
import Snackbar from './snackbar/snackbar';
import { addNewBook } from '../features/booksSlice';
import { useDispatch, useSelector } from 'react-redux';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function Calendar2(props) {
	const [value, onChange] = useState(new Date());
	const [datesAll, setDatesAll] = useState([]);
	const user = useSelector((state) => state.user.user);
	const snackbarRef = useRef();
	const dispatch = useDispatch();
	const handleSubmit = (e) => {
		e.preventDefault();
		const offset = value[0].getTimezoneOffset();
		const offset2 = value[1].getTimezoneOffset();
		let startDate2 = new Date(value[0].getTime() - offset * 60 * 1000);
		let endDate2 = new Date(value[1].getTime() - offset2 * 60 * 1000);
		const formData = new FormData();
		formData.append('check_in', startDate2.toISOString().split('T')[0]);
		formData.append('check_out', endDate2.toISOString().split('T')[0]);
		formData.append('room_id', 0);
		formData.append('guests', 0);
		formData.append('customer_id', 0);
		formData.append('price', 0);
		const response = axios
			.post('https://api.akallivilla.gr/api/admin/bookings', formData, {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			})
			.then((response) => {
				dispatch(addNewBook(response.data));
				snackbarRef.current.show();
			})
			.catch((error) => console.log(error));
	};
	const dateHandler = () => {
		let dates = [];
		props.Dates.forEach((element) => {
			let tmp = [];
			var dateArray = element.start.split('-');
			var year = dateArray[0];
			var month = dateArray[1] - 1;
			var day = dateArray[2];
			var check_inDate = new Date(year, month, day);
			tmp.push(check_inDate);
			dateArray = element.end.split('-');
			year = dateArray[0];
			month = dateArray[1] - 1;
			day = dateArray[2];
			var check_outDate = new Date(year, month, day);
			tmp.push(check_outDate);
			dates.push(tmp);
		});
		setDatesAll([...dates]);
	};
	function tileDisabled({ date, view }) {
		// Add class to tiles in month view only
		if (view === 'month') {
			// Check if a date React-Calendar wants to check is within any of the ranges
			return isWithinRanges(date, datesAll);
		}
	}

	function isWithinRange(date, range) {
		return isWithinInterval(date, { start: range[0], end: range[1] });
	}

	function isWithinRanges(date, ranges) {
		return ranges.some((range) => isWithinRange(date, range));
	}
	useEffect(() => {
		dateHandler();
	}, [props]);

	return (
		<div className="row-span-4 h-screen">
			<Calendar
				onChange={onChange}
				tileDisabled={tileDisabled}
				allowPartialRange={true}
				selectRange={true}
				minDate={new Date()}
			/>
			<button
				onClick={(e) => handleSubmit(e)}
				className="mt-8 mx-auto border-2 border-gray-800 text-xl p-2 rounded-xl hover:border-white hover:text-gray-800 hover:bg-white transition duration-500 ease-in-out"
			>
				Create Book
			</button>
			<Snackbar
				ref={snackbarRef}
				message="Book Created Successfully"
				type={SnackbarType.success}
			/>
		</div>
	);
}
