import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
	name: 'user',
	initialState: {
		user: {},
	},
	reducers: {
		login: (state, action) => {
			state.user = action.payload;
		},
		addUser: (state, action) => {
			state.user = [...state.user, action.payload];
		},
		logout: (state) => {
			state.user = {
				name: '',
				email: '',
				token: '',
			};
		},
	},
});
export const { login, logout, addUser } = userSlice.actions;
export const selectUser = (state) => state.user;
export default userSlice.reducer;
