import React, { useState, useRef } from 'react';
import './css/createRoomModal.css';
import axios from 'axios';
import Snackbar from './snackbar/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import {
	addImage,
	addImageSecondary,
	createRoom,
	reOrderRoom,
	updateRoom,
} from '../features/roomSlice';
import SecondaryImageDelete from './secondaryImageDelete';
import { Oval } from 'react-loader-spinner';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function RoomModalImage({ room, imageUrl }) {
	const snackbarRef = useRef(null);
	const [display, setDisplay] = useState(false);
	const [hover, setHover] = useState(false);
	const [loading, setLoading] = useState(false);
	const [roomName, setRoomName] = useState('');
	const [desc, setDescription] = useState('');
	const [price, setPrice] = useState(0);
	const [facilities, setFacilites] = useState('');
	const [minVisitors, setMinVisitors] = useState(0);
	const [maxVisitors, setMaxVisitors] = useState(0);
	const [mainImage, setMainImage] = useState(null);
	const [secondaryImage, setSecondaryImages] = useState([]);
	const [dragId, setDragId] = useState();
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const SubmitSecondaryImages = async (e, value) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('name', room.name);
		formData.append('description', room.description);
		formData.append('price', room.price);
		formData.append('facilities', room.facilities);
		formData.append('max_visitors', room.max_visitors);
		formData.append('min_visitors', room.min_visitors);
		formData.append('main_image', room.main_image);
		for (let i = 0; i < value.length; i++) {
			formData.append(`secondary_images_new[${i}]`, value[i]);
		}
		setLoading(true);
		const response = await axios
			.post(`https://api.akallivilla.gr/api/rooms/${room.id}`, formData, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${user.token}`,
				},
			})
			.then((response) => {
				dispatch(addImageSecondary(response.data.room[0]));
				setLoading(false);
				snackbarRef.current.show();
			})

			.catch((error) => console.log(error));
	};
	const handleDrag = (ev) => {
		ev.preventDefault();
		setDragId(ev.currentTarget.id);
		console.log('drag');
	};
	const handleDrop = (ev) => {
		ev.preventDefault();
		const dragImage = room.secondary_images.find(
			(image) => image === dragId
		);
		const dropImage = room.secondary_images.find(
			(image) => image === ev.currentTarget.id
		);

		let temp = [];

		if (dragImage !== dropImage) {
			let dropImageIndex;
			let dragImageIndex;
			for (let i = 0; i < room.secondary_images.length; i++) {
				if (room.secondary_images[i] === dropImage) {
					dropImageIndex = i;
				} else if (room.secondary_images[i] === dragImage) {
					dragImageIndex = i;
				}
			}
			for (let i = 0; i < room.secondary_images.length; i++) {
				if (dragImageIndex > dropImageIndex) {
					if (room.secondary_images[i] === dropImage) {
						temp.push(dragImage);
						temp.push(room.secondary_images[i]);
					} else if (room.secondary_images[i] !== dragImage) {
						temp.push(room.secondary_images[i]);
					}
				}
				if (dragImageIndex < dropImageIndex) {
					if (room.secondary_images[i] === dropImage) {
						temp.push(room.secondary_images[i]);
						temp.push(dragImage);
					} else if (room.secondary_images[i] !== dragImage) {
						temp.push(room.secondary_images[i]);
					}
				}
				// if (gallery.images[i] === dragImage) {
				// 	if (i === gallery.images.length - 1) {
				// 		test_string += dropImage;
				// 	} else {
				// 		test_string += dropImage + ',';
				// 	}

				// 	temp.push(dropImage);
				// } else if (gallery.images[i] === dropImage) {
				// 	if (i === gallery.images.length - 1) {
				// 		test_string += dragImage;
				// 	} else {
				// 		test_string += dragImage + ',';
				// 	}

				// 	temp.push(dragImage);
				// } else {
				// 	if (i === gallery.images.length - 1) {
				// 		test_string += gallery.images[i];
				// 	} else {
				// 		test_string += gallery.images[i] + ',';
				// 	}

				// 	temp.push(gallery.images[i]);
				// }
			}
			const response = axios
				.post(
					`https://api.akallivilla.gr/api/rooms/changeOrder/${room.id}`,
					{ secondary_images: temp },
					{
						headers: {
							Authorization: `Bearer ${user.token}`,
						},
					}
				)
				.then((response) =>
					dispatch(reOrderRoom({ id: room.id, images: temp }))
				)
				.catch((err) => console.log(err));
		}
	};
	const submitMainImage = async (e, value) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('name', room.name);
		formData.append('description', room.description);
		formData.append('price', room.price);
		formData.append('facilities', room.facilities);
		formData.append('max_visitors', room.max_visitors);
		formData.append('min_visitors', room.min_visitors);
		formData.append('main_image', value);
		for (let i = 0; i < secondaryImage.length; i++) {
			formData.append(`secondary_images[${i}]`, secondaryImage[i]);
		}
		const response = await axios
			.post(`https://api.akallivilla.gr/api/rooms/${room.id}`, formData, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${user.token}`,
				},
			})
			.then((response) => {
				console.log(response);
				dispatch(addImage(response.data.room[0]));
				snackbarRef.current.show();
			})

			.catch((error) => console.log(error));
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		console.log('first');
		var formData = new FormData();
		formData.append('name', roomName);
		formData.append('description', desc);
		formData.append('price', price);
		formData.append('facilities', facilities);
		formData.append('max_visitors', maxVisitors);
		formData.append('min_visitors', minVisitors);
		formData.append('main_image', mainImage);

		for (let i = 0; i < secondaryImage.length; i++) {
			formData.append(`secondary_images[${i}]`, secondaryImage[i]);
		}

		const response = axios
			.post('https://api.akallivilla.gr/api/rooms', formData, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				dispatch(createRoom(response.data.room));
				snackbarRef.current.show();
			})

			.catch((error) => console.log(error));
		setDisplay(false);
	};
	return (
		<>
			<img
				src={`${room.image_url}${imageUrl}`}
				alt={room.name}
				width={80}
				onClick={() => setDisplay(true)}
				style={{
					display: 'inline-block',
					height: 80,
					cursor: 'pointer',
				}}
			/>

			<Snackbar
				ref={snackbarRef}
				message="Room Created Successfully"
				type={SnackbarType.success}
			/>
			<div
				id="myModal"
				class="modal"
				style={{ display: display ? 'block' : 'none' }}
			>
				{loading ? (
					<div
						style={{
							width: 80,
							height: 80,
							display: 'block',
							marginLeft: 'auto',
							marginRight: 'auto',
							marginTop: 150,
						}}
					>
						<Oval color="#00BFFF" height={80} width={80} />
					</div>
				) : (
					<>
						<span class="close" onClick={() => setDisplay(false)}>
							&times;
						</span>

						<form
							className="bg-gray-800 mx-32 rounded-2xl py-4 shadow-2xl"
							encType="multipart/form-data"
						>
							<h4 className="text-center text-gray-400 text-xl">
								Images for Room {room.name}
							</h4>
							<div className="grid grid-cols-2">
								<div className="pt-16">
									<h3 className="text-center text-gray-400 text-xl">
										Main Image
									</h3>
									<img
										src={`${room.image_url}${room.main_image}`}
										width={400}
										className="mt-8 mx-auto"
										alt={room.main_image}
									/>
									<input
										type="file"
										placeholder="Main Image"
										className="mr-auto ml-auto"
										onChange={(e) =>
											submitMainImage(
												e,
												e.target.files[0]
											)
										}
										style={{
											marginTop: 14,
											width: '50%',
											height: 55,
											display: 'block',
											background: 'rgba(255,255,255,.4)',
											borderRadius: '12px',
											color: 'white',
											opacity: 1,
											padding: '12px',
											fontWeight: 600,
											fontStyle: 'italic',
										}}
									/>
								</div>
								<div className="pt-16">
									<h3 className="text-center text-gray-400 text-xl">
										Secondary Images
									</h3>
									<div className="grid grid-cols-3 gap-2">
										{room.secondary_images
											? room.secondary_images.map(
													(image) => {
														return (
															<SecondaryImageDelete
																setDragId={
																	setDragId
																}
																handleDrag={
																	handleDrag
																}
																handleDrop={
																	handleDrop
																}
																id={image}
																image={image}
																room={room}
															/>
														);
													}
											  )
											: ''}
									</div>
									<input
										type="file"
										onChange={(e) =>
											SubmitSecondaryImages(
												e,
												e.target.files
											)
										}
										multiple
										name="file[]"
										placeholder="Secondary Images"
										className="mr-auto ml-auto"
										style={{
											marginBottom: 24,
											marginTop: 24,
											width: '50%',
											height: 55,
											display: 'block',
											background: 'rgba(255,255,255,.4)',
											borderRadius: '12px',
											color: 'white',
											opacity: 1,
											padding: '12px',
											fontWeight: 600,
											fontStyle: 'italic',
										}}
									/>
								</div>
							</div>
						</form>
					</>
				)}
			</div>
		</>
	);
}
