import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import AdminTable from './adminTable';
import Snackbar from '../components/snackbar/snackbar';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import addUser from '../features/userSlice';
import { addNewAdmin } from '../features/adminsSlice';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function ProfileDetails({ id }) {
	const snackbarRef = useRef(null);
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [conf_pass, setConfPass] = useState('');
	const admins = useSelector((state) => state.admins);
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();
	const addAdmin = async (e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('name', name);
		formData.append('email', email);
		formData.append('password', password);
		formData.append('password_confirmation', conf_pass);
		if (password !== conf_pass) {
			snackbarRef.current.show();
			return;
		} else {
			const response = await axios
				.post('https://api.akallivilla.gr/api/register', formData, {
					headers: { Authorization: `Bearer ${user.token}` },
				})
				.then((res) => {
					console.log(res);
					dispatch(addNewAdmin(res.data.user));
				})
				.catch((error) => console.log(error));
		}
	};
	return (
		<div className="col-span-8 bg-gray-600 px-32 py-16 h-full">
			<div className="grid grid-cols-8">
				<div className="col-span-4 grid grid-cols-3 h-full">
					<h3
						className="col-span-3  text-gray-800 border-b-4 border-gray-800"
						style={{
							fontSize: 22,
							fontWeight: 600,

							textAlign: 'left',
						}}
					>
						Create New Admin
					</h3>
					<Snackbar
						ref={snackbarRef}
						message="Creation Failed! Try again."
						type={SnackbarType.fail}
					/>
					<form
						style={{
							marginTop: 48,
							textAlign: 'start',
							alignItems: 'start',
							justifyItems: 'start',
						}}
						className="col-span-3"
					>
						<input
							placeholder="Name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							className="w-1/2 bg-gray-800"
							style={{
								display: 'block',
								height: 50,
								color: 'white',
								borderRadius: 20,
								padding: 16,
							}}
						/>
						<input
							placeholder="Email"
							className="w-1/2 bg-gray-800"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							style={{
								display: 'block',
								height: 50,
								color: 'white',
								borderRadius: 20,
								padding: 16,
								marginTop: 16,
							}}
						/>
						<input
							placeholder="Password"
							className="w-1/2 bg-gray-800"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							style={{
								display: 'block',
								height: 50,

								color: 'white',
								borderRadius: 20,
								padding: 16,
								marginTop: 16,
							}}
						/>
						<input
							placeholder="Confirmation Password"
							value={conf_pass}
							onChange={(e) => setConfPass(e.target.value)}
							className="w-1/2 bg-gray-800"
							style={{
								display: 'block',
								height: 50,

								color: 'white',
								borderRadius: 20,
								padding: 16,
								marginTop: 16,
							}}
						/>

						<input
							className="w-32 bg-gray-800"
							type="submit"
							value="Create Admin"
							onClick={(e) => addAdmin(e)}
							style={{
								display: 'block',
								color: 'white',
								marginTop: 32,
								marginLeft: 'auto',
								marginRight: 'auto',
								textAlign: 'center',
								cursor: 'pointer',
								borderRadius: 20,
								border: '1px solid gray',
								padding: 10,
							}}
						/>
					</form>
				</div>
			</div>
			<div className="col-span-8">
				<AdminTable adminAccounts={admins.admins} />
			</div>
		</div>
	);
}
