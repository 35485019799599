import { createSlice } from '@reduxjs/toolkit';

export const adminsSlice = createSlice({
	name: 'admins',
	initialState: {
		admins: [],
	},
	reducers: {
		addAdmins: (state, action) => {
			state.admins = action.payload;
		},
		addNewAdmin: (state, action) => {
			state.admins = [...state.admins, action.payload];
		},
		deleteAdmin: (state, action) => {
			let arr = state.admins.filter((ad) => ad.id !== action.payload);
			state.admins = arr;
		},
		logout: (state) => {
			state.admins = [];
		},
	},
});
export const { addAdmins, logout, deleteAdmin, addNewAdmin } =
	adminsSlice.actions;
export const selectUser = (state) => state.user;
export default adminsSlice.reducer;
