import React, { useEffect } from 'react';
import ProfileDetails from '../components/profileDetails';
import Sidebar from '../components/sidebar';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
export default function Profile() {
	const params = useParams();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);

	useEffect(() => {
		if (!user.token) {
			navigate('/login');
		}
	}, []);
	return (
		<div className="grid grid-cols-9 gap-0 bg-gray-600 h-screen">
			<Sidebar brandName="Akalli" logo="../assets/logo.jpg" />
			<ProfileDetails id={params.id} />
		</div>
	);
}
