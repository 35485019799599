import React, { useEffect } from 'react';
import Sidebar from '../components/sidebar';
import RoomsContent from '../components/roomsContent';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
export default function Rooms() {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);

	useEffect(() => {
		if (!user.token) {
			navigate('/login');
		}
	}, []);
	return (
		<div className="grid grid-cols-9 bg-gray-600">
			<Sidebar brandName="Akalli" logo="../assets/logo.jpg" />
			<RoomsContent />
		</div>
	);
}
