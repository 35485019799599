import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteGallery,
	reOrderGallery,
	updateGallery,
} from '../features/gallerySlice';

import ImageCard from './imageCard';
import { Oval } from 'react-loader-spinner';
export default function DisplayGallery({ gallery }) {
	const dispatch = useDispatch();
	const [dragId, setDragId] = useState();
	const [display, setDisplay2] = useState(false);
	const [loading, setLoading] = useState(false);
	const user = useSelector((state) => state.user.user);
	const delGallery = () => {
		const response = axios
			.delete(`https://api.akallivilla.gr/api/galleries/${gallery.id}`, {
				headers: {
					Authorization: `Bearer ${user.token}`,
				},
			})
			.then((response) => dispatch(deleteGallery(gallery.id)))
			.catch((error) => console.log(error));
	};
	const uploadImages = (e, value) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append('description', gallery.description);
		for (let i = 0; i < value.length; i++) {
			formData.append(`images[${i}]`, value[i]);
		}
		setLoading(true);
		const response = axios
			.post(
				`https://api.akallivilla.gr/api/galleries/${gallery.id}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${user.token}`,
					},
				}
			)
			.then((response) => {
				dispatch(
					updateGallery({
						id: gallery.id,
						images: response.data.images,
					})
				);
				setLoading(false);
			})
			.catch((error) => console.log(error));
	};
	const handleDrag = (ev) => {
		ev.preventDefault();
		setDragId(ev.currentTarget.id);
		console.log('drag');
	};
	const handleDrop = (ev) => {
		ev.preventDefault();
		const dragImage = gallery.images.find((image) => image === dragId);
		const dropImage = gallery.images.find(
			(image) => image === ev.currentTarget.id
		);

		let temp = [];

		if (dragImage !== dropImage) {
			let dropImageIndex;
			let dragImageIndex;
			for (let i = 0; i < gallery.images.length; i++) {
				if (gallery.images[i] === dropImage) {
					dropImageIndex = i;
				} else if (gallery.images[i] === dragImage) {
					dragImageIndex = i;
				}
			}
			for (let i = 0; i < gallery.images.length; i++) {
				if (dragImageIndex > dropImageIndex) {
					if (gallery.images[i] === dropImage) {
						temp.push(dragImage);
						temp.push(gallery.images[i]);
					} else if (gallery.images[i] !== dragImage) {
						temp.push(gallery.images[i]);
					}
				}
				if (dragImageIndex < dropImageIndex) {
					console.log('first');
					if (gallery.images[i] === dropImage) {
						temp.push(gallery.images[i]);
						temp.push(dragImage);
					} else if (gallery.images[i] !== dragImage) {
						temp.push(gallery.images[i]);
					}
				}
				// if (gallery.images[i] === dragImage) {
				// 	if (i === gallery.images.length - 1) {
				// 		test_string += dropImage;
				// 	} else {
				// 		test_string += dropImage + ',';
				// 	}

				// 	temp.push(dropImage);
				// } else if (gallery.images[i] === dropImage) {
				// 	if (i === gallery.images.length - 1) {
				// 		test_string += dragImage;
				// 	} else {
				// 		test_string += dragImage + ',';
				// 	}

				// 	temp.push(dragImage);
				// } else {
				// 	if (i === gallery.images.length - 1) {
				// 		test_string += gallery.images[i];
				// 	} else {
				// 		test_string += gallery.images[i] + ',';
				// 	}

				// 	temp.push(gallery.images[i]);
				// }
			}
			const response = axios
				.post(
					`https://api.akallivilla.gr/api/galleries/changeOrder/${gallery.id}`,
					{ images: temp },
					{
						headers: {
							Authorization: `Bearer ${user.token}`,
						},
					}
				)
				.then((response) =>
					dispatch(reOrderGallery({ id: gallery.id, images: temp }))
				)
				.catch((err) => console.log(err));
		}
	};
	return (
		<div className="h-full">
			{loading ? (
				<div
					style={{
						width: 80,
						height: 80,
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto',
						marginTop: 150,
					}}
				>
					<Oval color="#00BFFF" height={80} width={80} />
				</div>
			) : (
				<>
					<h3 className="text-gray-300 text-center font-bold text-xl h-full mb-2 mt-8 borderline">
						<span
							className="text-2xl "
							style={{ fontFamily: 'Inconsolata' }}
						>
							{gallery.description}
							<hr />
						</span>
					</h3>
					<button
						className="mx-auto block mb-8 rounded-xl px-4 py-2 text-xl text-white bg-red-500 "
						onClick={() => setDisplay2(true)}
					>
						Delete Gallery
					</button>

					<div className="grid 2xl:grid-cols-8 grid-cols-4 gap-2">
						{gallery.images.length > 0
							? gallery.images.map((image, index) => {
									return (
										<div>
											<ImageCard
												id={image}
												setDragId={setDragId}
												handleDrag={handleDrag}
												handleDrop={handleDrop}
												image={image}
												gallery={gallery}
											/>
										</div>
									);
							  })
							: ''}
						<div className="flex items-center align-middle">
							<label
								htmlFor={gallery.id}
								className="rounded-full bg-slate-400 text-white font-bold p-2 h-16 w-16 text-center mx-auto"
							>
								<h2 className="mt-1 text-3xl">+</h2>
							</label>
							<input
								id={gallery.id}
								type="file"
								onChange={(e) =>
									uploadImages(e, e.target.files)
								}
								multiple
								name="file[]"
								placeholder="Secondary Images"
								className="mr-auto ml-auto"
								hidden
								style={{
									marginBottom: 24,
									width: '50%',
									height: 55,

									background: 'rgba(255,255,255,.4)',
									borderRadius: '12px',
									color: 'white',
									opacity: 1,
									padding: '12px',
									fontWeight: 600,
									fontStyle: 'italic',
								}}
							/>
						</div>
					</div>
					<div
						id="myModal2"
						class="modal"
						style={{
							display: display ? 'block' : 'none',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div className="mx-auto  block">
							<div className="bg-white h-32 w-64 mx-auto mt-64 text-center py-8">
								<h3 className="text-black">
									Are you sure you want to delete?
								</h3>
								<button
									className="bg-red-800 mr-4 mt-4 w-12 rounded-2xl"
									onClick={() => delGallery()}
								>
									Yes
								</button>
								<button
									className="text-black w-12 rounded-2xl border-2"
									onClick={(e) => {
										e.preventDefault();
										setDisplay2(false);
									}}
								>
									No
								</button>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}
