import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBook, updateBook } from '../features/booksSlice';
import Snackbar from './snackbar/snackbar';
import CustomerModal from './customerModal';
const SnackbarType = {
	success: 'success',
	fail: 'fail',
};
export default function SingleRowBook({ book }) {
	const user = useSelector((state) => state.user.user);
	const [excludedDates, setExcludedDates] = useState([]);
	const [dateRange, setDateRange] = useState([
		new Date(book.check_in),
		new Date(book.check_out),
	]);
	const [startDate, endDate] = dateRange;
	const [checkIn, setCheckIn] = useState('');
	const [checkOut, setCheckOut] = useState('');
	const [price, setPrice] = useState(0);
	const [guests, setGuests] = useState(0);
	const [status, setStatus] = useState('pending');
	const dispatch = useDispatch();
	const snackbarRef = useRef(null);
	const deleteBookById = async (e, id) => {
		e.preventDefault();
		const response = await axios
			.delete(`https://api.akallivilla.gr/api/bookings/${id}`, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${user.token}`,
				},
			})
			.then((response) => {
				dispatch(deleteBook(id));
			})
			.catch((error) => console.log(error));
	};
	const updateBookById = async (e, id) => {
		e.preventDefault();
		console.log(
			startDate,
			endDate,
			price,
			guests,
			status,
			book.room_id,
			book.customer_id
		);
		const formData = new FormData();
		formData.append('id', book.id);
		const offset = startDate.getTimezoneOffset();
		const offset2 = endDate.getTimezoneOffset();
		let startDate2 = new Date(startDate.getTime() - offset * 60 * 1000);
		let endDate2 = new Date(endDate.getTime() - offset2 * 60 * 1000);
		formData.append('check_in', startDate2.toISOString().split('T')[0]);
		formData.append('check_out', endDate2.toISOString().split('T')[0]);
		formData.append('room_id', book.room_id);
		formData.append('price', price);
		formData.append('status', status);
		formData.append('guests', guests);
		console.log(status);
		formData.append('customer_id', book.customer_id);
		const response = axios
			.post(
				`https://api.akallivilla.gr/api/bookings/${book.id}`,
				formData,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${user.token}`,
					},
				}
			)
			.then((response) => {
				if (response.status === 200 || response.status === 201) {
					snackbarRef.current.show();
				}
			})
			.catch((error) => console.log(error));
		dispatch(
			updateBook({
				id: id,
				check_in: startDate.toISOString(),
				check_out: endDate.toISOString(),
				price: price,
				guests: guests,
				status: status,
			})
		);
	};
	useEffect(() => {
		const fetchData = async () => {
			const response = await axios
				.get(`https://api.akallivilla.gr/api/bookings/closedDates`)
				.then((res) => {
					if (res.data) {
						return res.data;
					}
				})
				.catch((error) => {
					console.log(error);
				});
			let dates = [];
			for (let i = 0; i < response.length; i++) {
				const start = new Date(response[i].start);
				const end = new Date(response[i].end);
				let moving = start;
				while (moving <= new Date(end)) {
					dates.push(new Date(moving));
					moving.setUTCDate(moving.getUTCDate() + 1);
				}
			}
			setExcludedDates([...dates]);
		};
		fetchData();
		setDateRange([new Date(book.check_in), new Date(book.check_out)]);
		setStatus(book.status);
		setPrice(book.price);
		setGuests(book.guests);
	}, [book]);
	const validCheck = () => {
		let dates = [];
		let first = new Date(startDate);
		while (first <= dateRange[1]) {
			dates.push(new Date(first));
			first.setUTCDate(first.getUTCDate() + 1);
		}
		let flag = 0;
		dates.map((date) => {
			for (let i = 0; i < excludedDates.length; i++) {
				if (
					date.getFullYear() === excludedDates[i].getFullYear() &&
					date.getDate() === excludedDates[i].getDate() &&
					date.getMonth() === excludedDates[i].getMonth()
				) {
					flag = 1;

					return flag;
				}
			}
			return flag;
		});
		return flag;
	};
	return (
		<tr class=" hover:bg-gray-700">
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<Link to={`/book/${book.id}`}>{book.id}</Link>
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<Link to={`/room/${book.room_id}`}>{book.room_id}</Link>
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<DatePicker
					selectsRange={true}
					startDate={startDate}
					endDate={endDate}
					dateFormat="dd/MM/yyyy"
					placeholderText="Select Dates"
					onChange={(update) => {
						setDateRange(update);
					}}
					excludeDates={excludedDates}
					highlightDates={excludedDates}
					minDate={new Date()}
					withPortal
					className="w-48 cursor-pointer bg-gray-800"
					showMonthDropdown
					useShortMonthInDropdown
				/>
				<Snackbar
					ref={snackbarRef}
					message="Updated Successfully!"
					type={SnackbarType.success}
				/>
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<CustomerModal id={book.customer_id} />
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<input
					className="bg-gray-800 w-12"
					defaultValue={book.guests}
					type="number"
					onChange={(e) => setGuests(e.target.value)}
				/>
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				<input
					className="bg-gray-800 w-16"
					defaultValue={book.price}
					type="number"
					onChange={(e) => setPrice(e.target.value)}
				/>
			</td>
			<td class="py-4 px-1 text-sm font-medium  whitespace-nowrap text-white">
				{status === 'booked' ? (
					<img
						src="assets/icons8-tick-24.png"
						width={24}
						height={24}
						alt="tick"
						style={{ display: 'inline-block' }}
					/>
				) : (
					<img
						src="assets/icons8-warning-32.png"
						width={24}
						height={24}
						alt="warning"
						style={{ display: 'inline-block' }}
					/>
				)}

				<select
					name="drinks"
					className="bg-gray-800 w-24"
					value={status}
					onChange={(e) => setStatus(e.target.value)}
				>
					{book.status === 'booked' ? (
						<>
							<option defaultValue={book.status}>
								{book.status}
							</option>
							<option value="pending">pending</option>
						</>
					) : (
						<>
							<option defaultValue="pending">pending</option>
							<option
								value="booked"
								onChange={(val) => setStatus(val)}
							>
								Booked
							</option>
						</>
					)}
				</select>
			</td>
			<td class="py-4 px-1 text-sm font-medium text-center whitespace-nowrap">
				<button
					className="border-2 border-red-400 w-6 bg-red-400 rounded text-white font-bold"
					onClick={(e) => deleteBookById(e, book.id)}
				>
					X
				</button>
			</td>
			<td class="py-4 px-1 text-sm font-medium text-center whitespace-nowrap">
				<button
					className="border-2 border-gray-700 w-full bg-gray-700 rounded text-white font-bold"
					onClick={(e) => updateBookById(e, book.id)}
				>
					Update
				</button>
			</td>
		</tr>
	);
}
