import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/sidebar';
export default function Room() {
	const params = useParams();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);

	useEffect(() => {
		if (!user.token) {
			navigate('/login');
		}
		if (Object.entries(user).length !== 0) {
		}
	}, []);
	return (
		<div className="grid grid-cols-9 bg-gray-600">
			<Sidebar brandName="Akalli" logo="../assets/logo.jpg" />
		</div>
	);
}
