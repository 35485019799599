import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function TotalPrice() {
	const [money, setMoney] = useState(0);
	const books = useSelector((state) => state.books.books);
	const calculateMoney = () => {
		let sum = 0;
		for (let i = 0; i < books.length; i++) {
			if (books[i].status === 'booked') {
				sum += books[i].price;
			}
		}
		console.log(sum);
		return sum;
	};
	useEffect(() => {
		setMoney(calculateMoney());
	}, []);
	return (
		<div className="h-32 rounded-xl bg-white text-center py-2 col-span-3 row-span-4 mt-2">
			<h2 className="text-gray-500">Money from Booked Status</h2>
			<h6 className="text-gray-700 font-bold text-2xl mt-8">
				{money} &euro;
			</h6>
		</div>
	);
}
