import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Profile from '../components/profile';
import Sidebar from '../components/sidebar';
import { addRooms } from '../features/roomSlice';
import { addBooks } from '../features/booksSlice';
import { addCustomers } from '../features/customersSlice';
import { addAdmins } from '../features/adminsSlice';
import { allDates } from '../features/dateSlice';
import { addGallery } from '../features/gallerySlice';
import axios from 'axios';
export default function Home() {
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!user.token) {
			navigate('/login');
		}
		if (Object.entries(user).length !== 0) {
		}
	}, []);
	return (
		<>
			<div className="grid grid-cols-9 bg-gray-600 h-full">
				<Sidebar brandName="Akalli" logo="../assets/logo.jpg" />
				<Profile />
			</div>
		</>
	);
}
