import axios from 'axios';
import React, { useState } from 'react';
import { deleteImage } from '../features/roomSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function SecondaryImageDelete({
	image,
	room,
	handleDrag,
	setDragId,
	handleDrop,
	id,
}) {
	const [hover, setHover] = useState(false);
	const [display, setDisplay2] = useState(false);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user.user);
	const delImageSpecific = async (e, url) => {
		e.preventDefault();
		const formData = new FormData();
		console.log(url);
		formData.append('secondary_images', url);
		const response = await axios
			.post(
				`https://api.akallivilla.gr/api/rooms/imgDelete/${room.id}`,
				formData,
				{ headers: { Authorization: `Bearer ${user.token}` } }
			)
			.then((response) => {
				setDisplay2(false);
				dispatch(deleteImage({ id: room.id, imageUrl: url }));
			})
			.catch((error) => console.log(error));
	};
	return (
		<div className="relative">
			<img
				draggable={true}
				onDragOver={(ev) => ev.preventDefault()}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				id={id}
				onDrag={handleDrag}
				onDrop={handleDrop}
				src={`${room.image_url}${image}`}
				alt={image}
				width={200}
				style={{
					height: 140,
					filter: hover ? 'brightness(20%)' : '',
					transition: 'filter 1s ease-out',
					cursor: 'pointer',
				}}
				className="mt-8 mx-auto cursor-pointer"
				onClick={() => setDisplay2(true)}
			/>
			<div
				id="myModal2"
				class="modal"
				style={{
					display: display ? 'block' : 'none',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div className="mx-auto  block">
					<div className="bg-white h-32 w-64 mx-auto mt-64 text-center py-8">
						<h3 className="text-black">
							Are you sure you want to delete?
						</h3>
						<button
							className="bg-red-800 mr-4 mt-4 w-12 rounded-2xl"
							onClick={(e) => delImageSpecific(e, image)}
						>
							Yes
						</button>
						<button
							className="text-black w-12 rounded-2xl border-2"
							onClick={(e) => {
								e.preventDefault();
								setDisplay2(false);
							}}
						>
							No
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
