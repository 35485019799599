import React from 'react';
import { useSelector } from 'react-redux';

export default function PendingBooks() {
	const books = useSelector((state) => state.books.books);
	const findPendingBooks = () => {
		let sum = 0;
		for (let i = 0; i < books.length; i++) {
			if (books[i].status === 'pending') {
				sum++;
			}
		}
		return sum;
	};
	return (
		<div className="h-32 rounded-xl bg-white text-center py-2 col-span-3 row-span-4">
			<h2 className="text-gray-500">Pending Books</h2>
			<h6 className="text-gray-700 font-bold text-2xl mt-8">
				{findPendingBooks()}
			</h6>
		</div>
	);
}
