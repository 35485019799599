import { createSlice } from '@reduxjs/toolkit';

export const dateSlice = createSlice({
	name: 'dates',
	initialState: {
		dates: [],
	},
	reducers: {
		allDates: (state, action) => {
			state.dates = action.payload;
		},

		logoutDates: (state) => {
			state.dates = [];
		},
	},
});
export const { allDates, logoutDates } = dateSlice.actions;
export const selectDates = (state) => state.dates;
export default dateSlice.reducer;
