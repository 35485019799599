import { createSlice } from '@reduxjs/toolkit';

export const gallerySlice = createSlice({
	name: 'gallery',
	initialState: {
		gallery: [],
	},
	reducers: {
		addGallery: (state, action) => {
			state.gallery = action.payload;
		},
		createGallery: (state, action) => {
			state.gallery = [...state.gallery, action.payload];
		},
		deleteGallery: (state, action) => {
			let arr = state.gallery.filter((ad) => ad.id !== action.payload);
			state.gallery = arr;
		},
		deleteImage: (state, action) => {
			state.gallery = state.gallery.map((card) => {
				if (card.id === action.payload.id) {
					const images = card.images.filter(
						(imag) => imag !== action.payload.imageUrl
					);
					return { ...card, images: [...images] };
				}
				return card;
			});
		},
		reOrderGallery: (state, action) => {
			state.gallery = state.gallery.map((gal) => {
				if (gal.id === action.payload.id) {
					return {
						...gal,
						images: action.payload.images,
					};
				}
				return gal;
			});
		},
		updateGallery: (state, action) => {
			state.gallery = state.gallery.map((card) => {
				if (card.id === action.payload.id) {
					return {
						...card,
						images: action.payload.images,
					};
				}
				return card;
			});
		},
		logoutGallery: (state) => {
			state.gallery = [];
		},
	},
});
export const {
	addGallery,
	logoutGallery,
	createGallery,
	deleteGallery,
	updateGallery,
	deleteImage,
	reOrderGallery,
} = gallerySlice.actions;
export const selectUser = (state) => state.user;
export default gallerySlice.reducer;
