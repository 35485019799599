import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Calendar2 from './calendar';
import CreateBookModal from './createBookModal';

import './css/calendar.css';
import PendingBooks from './widgets/pendingBooks';

import TotalPrice from './widgets/totalPrice';

export default function Profile() {
	const [date, onChange] = useState(new Date());
	const [dates, setDates] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	useEffect(() => {
		const fetchData = async () => {
			const response = await axios
				.get(`https://api.akallivilla.gr/api/bookings/closedDates`)
				.then((res) => {
					if (res.data) {
						setDates([...res.data]);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		};
		fetchData();
	}, []);

	const params = useParams();
	console.log(dates);
	return (
		<div
			style={{
				marginRight: 32,
				marginTop: 32,
			}}
			className="col-span-8 grid grid-cols-8 grid-rows-7 flex-1 overflow-y-hidden"
		>
			<div className="col-span-8 row-span-1 h-[50px]"></div>
			<div
				className="col-span-5 row-span-4 h-full row-start-2 px-32 dark:text-gray-200"
				style={{
					fontSize: 22,
					fontWeight: 600,
					textAlign: 'center',
				}}
			>
				<h3 className="mb-4">Booked Dates</h3>

				<Calendar2 Dates={dates} />

				{/* <CreateBookModal /> */}
			</div>
			<div className="col-span-3 row-span-1 h-full px-32 ">
				<PendingBooks />
				<TotalPrice />
			</div>
		</div>
	);
}
