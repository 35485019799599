import React from 'react';
import { useSelector } from 'react-redux';
import CreateGalleryModal from './createGalleryModal';
import DisplayGallery from './displayGallery';

export default function GallerySection() {
	const galleries = useSelector((state) => state.gallery.gallery);
	return (
		<div className="col-span-8 px-32 h-full">
			<div class="flex flex-col" style={{ marginTop: 32 }}>
				<CreateGalleryModal />
			</div>
			<div>
				{galleries.message === '' || !galleries.message
					? galleries.map((gallery) => {
							return <DisplayGallery gallery={gallery} />;
					  })
					: ''}
			</div>
		</div>
	);
}
