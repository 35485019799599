import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const booksSlice = createSlice({
	name: 'books',
	initialState: {
		books: [],
	},
	reducers: {
		addBooks: (state, action) => {
			state.books = action.payload;
		},
		deleteBook: (state, action) => {
			state.books = state.books.filter(
				(card) => card.id !== action.payload
			);
		},
		addNewBook: (state, action) => {
			state.books = [...state.books, action.payload];
		},
		updateBook: (state, action) => {
			state.books = state.books.map((card) => {
				if (card.id === action.payload.id) {
					return {
						...card,
						check_in: action.payload.check_in,
						check_out: action.payload.check_out,
						guests: action.payload.guests,
						price: action.payload.price,
						status: action.payload.status,
					};
				}
				return card;
			});
		},
		logoutBook: (state) => {
			state.books = [];
		},
	},
});
export const {
	addBooks,
	logout,
	updateBook,
	deleteBook,
	addNewBook,
	logoutBook,
} = booksSlice.actions;
export const selectUser = (state) => state.user;
export default booksSlice.reducer;
