import { createSlice } from '@reduxjs/toolkit';

export const customersSlice = createSlice({
	name: 'customers',
	initialState: {
		customers: [],
	},
	reducers: {
		addCustomers: (state, action) => {
			state.customers = action.payload;
		},
		updateCustomer: (state, action) => {
			state.customers = state.customers.map((cust) => {
				if (cust.id === action.payload.id) {
					return {
						...cust,
						customer_first_name: action.payload.fname,
						customer_last_name: action.payload.lname,
						customer_email: action.payload.email,
						customer_phone: action.payload.phone,
					};
				}
				return cust;
			});
		},
		logoutCustomer: (state) => {
			state.customers = [];
		},
	},
});
export const { addCustomers, logoutCustomer, updateCustomer } =
	customersSlice.actions;
export const selectUser = (state) => state.user;
export default customersSlice.reducer;
