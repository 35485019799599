import React from 'react';
import { useSelector } from 'react-redux';
import CreateRoomModal from './createRoomModal';
import SingleRoomRow from './singleRoomRow';

export default function RoomsContent() {
	const rooms = useSelector((state) => state.rooms.rooms);
	console.log(rooms);
	return (
		<div className="col-span-8 h-screen">
			<div class="flex flex-col" style={{ marginTop: 32 }}>
				<CreateRoomModal />
				<div class="overflow-x-auto shadow-md sm:rounded-lg">
					<div class="inline-block min-w-full align-middle bg-gray-800">
						<div class="p-4">
							<label for="table-search" class="sr-only">
								Search
							</label>
							<div class="relative mt-1">
								<div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
									<svg
										class="w-5 h-5 text-gray-400 dark:text-gray-400"
										fill="currentColor"
										viewBox="0 0 20 20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
											clip-rule="evenodd"
										></path>
									</svg>
								</div>
								<input
									type="text"
									id="table-search"
									class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Search for items"
								/>
							</div>
						</div>
						<div class="overflow-hidden">
							<table class="min-w-full divide-y divide-gray-700 table-fixed dark:divide-gray-700">
								<thead class="bg-gray-700 dark:bg-gray-700">
									<tr>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-400 uppercase dark:text-gray-400"
										>
											Room ID
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-400 uppercase dark:text-gray-400"
										>
											Room Name
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-400 uppercase dark:text-gray-400"
										>
											Description
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-400 uppercase dark:text-gray-400"
										>
											Min Visitors
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-400 uppercase dark:text-gray-400"
										>
											Max Visitors
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-400 uppercase dark:text-gray-400"
										>
											Main Image
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-400 uppercase dark:text-gray-400"
										>
											Secondary Image
										</th>
										<th
											scope="col"
											class="py-3 px-1 text-xs font-medium tracking-wider text-left text-gray-400 uppercase dark:text-gray-400"
										>
											Price
										</th>
										<th
											scope="col"
											class="p-1 text-red-700"
										>
											Delete
										</th>
										<th
											scope="col"
											class="p-1 text-gray-400"
										>
											Update
										</th>
									</tr>
								</thead>
								<tbody class="hover:bg-gray-600  bg-gray-800 divide-gray-700">
									{rooms.length > 0
										? rooms.map((room) => {
												return (
													<SingleRoomRow
														room={room}
													/>
												);
										  })
										: ''}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
